import React, { useEffect, useMemo, useRef, useState } from 'react';
import { CustomPicker } from 'react-color';
import { Alpha, Hue, Saturation } from 'react-color/lib/components/common';
import classNames from 'classnames';
import IconButton from '@mui/material/IconButton';
import { BrandGuideColor, Color, ColorOptions } from 'types/color.type';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import cloneDeep from 'helpers/cloneDeep';
import Translation from 'components/data/Translation';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import Setup from 'components/data/Setup';
import { NumberInputField } from 'components/template-designer/components/ui-components/number-input-field';
import { StylingHelpers } from 'components/template-designer/helpers/styling.helpers';
import { ColorHelpers } from 'helpers/colors.helpers';
import { HexInput } from './hex-input';
import { UseColorPickerReturnType } from '../hooks/useColorPicker';
import '../styles/color-picker-modal.scss';

const GRADIENT_BAR_CLASSNAME = 'color-picker-modal__gradient-container__bar';
const GRADIENT_BAR_POINT_CLASSNAME = `${GRADIENT_BAR_CLASSNAME}__point`;

interface EyeDropper {
    open(): Promise<{ sRGBHex: string }>;
}

declare global {
    interface Window {
        EyeDropper?: {
            new (): EyeDropper;
        };
    }
}

interface Props {
    hexValue: UseColorPickerReturnType['hexValue'];
    rgbValue: UseColorPickerReturnType['rgbValue'];
    onChangeColorType: UseColorPickerReturnType['onChangeColorType'];
    onChangeRotation: UseColorPickerReturnType['onChangeRotation'];
    onChangePoints: UseColorPickerReturnType['onChangePoints'];
    onChange: UseColorPickerReturnType['onChangeColor'];
    onChangePreset: UseColorPickerReturnType['onChangePreset'];
    onChangeInput: UseColorPickerReturnType['onChangeInput'];
    activePoint: UseColorPickerReturnType['activePoint'];
    onChangeActivePoint: UseColorPickerReturnType['onChangeActivePoint'];
    colorType: ColorOptions;
    colorPoints: Color['points'];
    presetColors: BrandGuideColor[];
    getPresetColors: () => BrandGuideColor[];
    rotation: Color['rotation'];
    useGradients: boolean;
    usePipette: boolean;
    useAlpha: boolean;
}

const ColorPickerModal = ({
    hexValue,
    rgbValue,
    onChangeColorType,
    onChangeRotation,
    onChangePoints,
    onChange,
    onChangePreset,
    onChangeInput,
    onChangeActivePoint,
    activePoint,
    colorType = ColorOptions.Solid,
    colorPoints,
    presetColors,
    rotation,
    useGradients,
    usePipette,
    useAlpha,
    ...props
}: Props) => {
    const [activeDrag, setActiveDrag] = useState<number | null>(null);
    const [startPos, setStartPos] = useState<{ mouse: number; point: number }>({ mouse: 0, point: 0 });
    const [canDelete, setCanDelete] = useState<boolean>(false);
    const pointsBar = useRef<HTMLDivElement>(null);

    // Is true if the EyeDropper API is available and the user has enabled the use of the pipette.
    const canUseEyeDropper = typeof window.EyeDropper === 'function' && usePipette;

    /**
     * Create mouse and keydown listener for handling the points bar.
     * Add, move, delete color point.
     */
    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousedown', handleCanDelete);
        document.addEventListener('keydown', handleDelete);

        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousedown', handleCanDelete);
            document.removeEventListener('keydown', handleDelete);
        };
    }, [colorPoints, canDelete, activeDrag, startPos]);

    /**
     * Gets the filtered preset colors.
     * If gradients are enabled, return all colors except transparent.
     * If gradients are disabled, return only solid colors.
     */
    const filteredPresetColors = useMemo(() => {
        if (!presetColors) return [];

        if (useGradients) {
            return presetColors.filter((presetColor) => presetColor.color.type !== ColorOptions.Transparent);
        }

        return presetColors.filter((presetColor) => presetColor.color.type === ColorOptions.Solid);
    }, [presetColors, useGradients]);

    /**
     * Handles the background type change.
     */
    const changeBackgroundType = (colorOption: ColorOptions) => {
        // Set active point to 0.
        onChangeActivePoint(0);
        // Pass the new type.
        onChangeColorType(colorOption);
    };

    /**
     * Change state when the mouse is down on a color point.
     * @param event - The mouse event.
     * @param index - The index of the color point.
     */
    const handleMouseDown = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
        event.stopPropagation();

        // Set current active drag.
        setActiveDrag(index);

        // Set the correct active point.
        onChangeActivePoint(index);

        // Set the start position.
        setStartPos({ mouse: event.pageX, point: colorPoints[index].location });

        // Set the gradient position, so it can be deleted.
        setCanDelete(true);
    };

    /**
     * Calculates the new color point position based on mouse movement.
     * Save the new color points.
     */
    const handleMouseMove = (event: MouseEvent) => {
        if (activeDrag === null || !pointsBar.current) return;

        const { offsetWidth } = pointsBar.current;
        const change = (event.pageX - startPos.mouse) / offsetWidth;

        const points = cloneDeep(colorPoints);
        if (startPos.point + change > 1) {
            points[activeDrag].location = 1;
        } else if (startPos.point + change < 0) {
            points[activeDrag].location = 0;
        } else {
            points[activeDrag].location = Math.round((startPos.point + change) * 100) / 100;
        }

        onChangePoints(points);
    };

    /**
     * Disables the active drag when mouse is released.
     */
    const handleMouseUp = () => {
        setActiveDrag(null);
    };

    /**
     * Checkes if the color points can be deleted by checking the className.
     * @param event - The mouse event.
     */
    const handleCanDelete = (event: MouseEvent) => {
        const target = event.target as HTMLElement;

        if (target.classList.contains(GRADIENT_BAR_POINT_CLASSNAME)) {
            return setCanDelete(true);
        }

        setCanDelete(false);
    };

    /**
     * Deletes a color point.
     * @param event - The keydown event.
     */
    const handleDelete = (event: KeyboardEvent) => {
        // Can the color point be deleted.
        if (!canDelete) return;

        // Is the right key pressed.
        if (event.code !== 'Delete' && event.code !== 'Backspace') return;

        event.preventDefault();
        event.stopPropagation();

        // Are there enough color points.
        if (colorPoints.length <= 2) {
            return SnackbarUtils.warning(Translation.get('general.errors.colorPoints', 'template-designer'));
        }

        const points = cloneDeep(colorPoints);

        // Delete the color point.
        points.splice(activePoint, 1);

        onChangeActivePoint(0);

        // Saves the color points.
        onChangePoints(points);
        setCanDelete(false);
    };

    /**
     * Add a color point when clicking on the color points bar.
     * @param event - The mouse event.
     */
    const addColorPoint = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!pointsBar.current) return;

        const target = event.target as HTMLElement;

        // Checks if the color points bar is clicked.
        if (target.className !== GRADIENT_BAR_CLASSNAME) return;

        // Calculates the pointer location.
        const location = (event.pageX - pointsBar.current.getBoundingClientRect().left) / pointsBar.current.offsetWidth;
        const points = cloneDeep(colorPoints);
        points.push({ location, color: cloneDeep(points[activePoint].color) });

        // Sorts the color points.
        points.sort((a, b) => a.location - b.location);

        // Calculates the new active point.
        const newActivePoint = points.findIndex((p) => p.location === location);
        onChangeActivePoint(newActivePoint);

        onChangePoints(points);
        setCanDelete(true);
    };

    /**
     * Handles the preset colors.
     * @param color - The color object.
     */
    const handlePresetColors = (color: BrandGuideColor) => {
        onChangePreset(color.color);
    };

    /**
     * Activated the EyeDropper API of the browser and change the current color.
     */
    const onEyeDropper = () => {
        if (!window.EyeDropper || !canUseEyeDropper) return;

        const eyeDropper = new window.EyeDropper();

        // Enter eyedropper mode.
        eyeDropper.open().then((colorSelectionResult) => {
            // Returns hex color value (#RRGGBB) of the selected pixel
            onChangeInput('hex', colorSelectionResult.sRGBHex, true);
        });
    };

    /**
     * Checks if the color points bar can be shown by checking the right color types.
     */
    const showColorPointsBar = useMemo(() => [ColorOptions.Linear, ColorOptions.Radial].includes(colorType), [colorType]);

    /**
     * Categorise colors into categories.
     */
    const generalColors = useMemo(() => filteredPresetColors && filteredPresetColors.filter((color) => color.brand === 'general'), [presetColors]);
    const brandColors = useMemo(
        () => filteredPresetColors && filteredPresetColors.filter((color) => color.brand !== 'general' && color.brand !== 'template'),
        [presetColors]
    );
    const templateColors = useMemo(() => filteredPresetColors && filteredPresetColors.filter((color) => color.brand === 'template'), [presetColors]);

    return (
        <div className="color-picker-modal">
            <div
                className={classNames('color-picker-modal__type-selection', {
                    'color-picker-modal__type-selection--transparent-selected': colorType === ColorOptions.Transparent
                })}>
                <Tooltip title={Translation.get('colorPicker.types.solid', 'ui-components')} placement="top">
                    <button
                        className={classNames(
                            'color-picker-modal__type-selection__button',
                            `color-picker-modal__type-selection__button--${ColorOptions.Solid}`,
                            {
                                ['color-picker-modal__type-selection__button--active']: colorType === ColorOptions.Solid
                            }
                        )}
                        name={ColorOptions.Solid}
                        onClick={(event) => changeBackgroundType(event.currentTarget.name as ColorOptions)}
                    />
                </Tooltip>
                {useGradients && (
                    <>
                        <Tooltip title={Translation.get('colorPicker.types.gradient', 'ui-components')} placement="top">
                            <button
                                className={classNames(
                                    'color-picker-modal__type-selection__button',
                                    `color-picker-modal__type-selection__button--${ColorOptions.Linear}`,
                                    {
                                        ['color-picker-modal__type-selection__button--active']: colorType === ColorOptions.Linear
                                    }
                                )}
                                name={ColorOptions.Linear}
                                onClick={(event) => changeBackgroundType(event.currentTarget.name as ColorOptions)}
                            />
                        </Tooltip>
                        <Tooltip title={Translation.get('colorPicker.types.radialGradient', 'ui-components')} placement="top">
                            <button
                                className={classNames(
                                    'color-picker-modal__type-selection__button',
                                    `color-picker-modal__type-selection__button--${ColorOptions.Radial}`,
                                    {
                                        ['color-picker-modal__type-selection__button--active']: colorType === ColorOptions.Radial
                                    }
                                )}
                                name={ColorOptions.Radial}
                                onClick={(event) => changeBackgroundType(event.currentTarget.name as ColorOptions)}
                            />
                        </Tooltip>
                    </>
                )}
                {useAlpha && (
                    <Tooltip title={Translation.get('colorPicker.types.transparent', 'ui-components')} placement="top">
                        <button
                            className={classNames(
                                'color-picker-modal__type-selection__button',
                                `color-picker-modal__type-selection__button--${ColorOptions.Transparent}`,
                                {
                                    ['color-picker-modal__type-selection__button--active']: colorType === ColorOptions.Transparent
                                }
                            )}
                            name={ColorOptions.Transparent}
                            onClick={(event) => changeBackgroundType(event.currentTarget.name as ColorOptions)}
                        />
                    </Tooltip>
                )}
            </div>

            {showColorPointsBar && (
                <div className="color-picker-modal__gradient-container">
                    <div
                        ref={pointsBar}
                        style={StylingHelpers.getLayerStyle({
                            background: { type: ColorOptions.Linear, rotation: 90, points: colorPoints }
                        })}
                        className="color-picker-modal__gradient-container__bar"
                        onClick={addColorPoint}>
                        {colorPoints.map((point, i) => (
                            <div
                                key={point.location}
                                onMouseDown={(event) => handleMouseDown(event, i)}
                                className={classNames(GRADIENT_BAR_POINT_CLASSNAME, activePoint === i && 'active')}
                                style={{ left: point.location * 100 + '%' }}
                            />
                        ))}
                    </div>

                    {colorType === ColorOptions.Linear && (
                        <NumberInputField
                            className="color-picker-modal__gradient-container__degrees"
                            size="small"
                            min={0}
                            max={360}
                            value={rotation}
                            onChange={(value) => onChangeRotation(value)}
                            InputProps={{
                                endAdornment: <div>deg</div>
                            }}
                        />
                    )}
                </div>
            )}

            {colorType !== ColorOptions.Transparent && (
                <>
                    <div className="color-picker-modal__picker">
                        <div className="color-picker-modal__picker__saturation">
                            <Saturation {...props} onChange={onChange} />
                        </div>
                        <div className="color-picker-modal__picker__pipette-sliders">
                            {canUseEyeDropper && (
                                <IconButton className="color-picker-modal__picker__pipette" size="small" onClick={onEyeDropper}>
                                    <Icon>colorize</Icon>
                                </IconButton>
                            )}
                            <div className="color-picker-modal__picker__pipette-sliders__sliders">
                                <div className="color-picker-modal__picker__hue">
                                    <Hue {...props} onChange={onChange} />
                                </div>
                                {useAlpha && (
                                    <div className="color-picker-modal__picker__alpha">
                                        <Alpha {...props} onChange={onChange} />
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="color-picker-modal__picker__input-fields">
                            <HexInput
                                className="color-picker-modal__picker__input-fields__hex"
                                colorType={colorType}
                                showColorButton={false}
                                onChangeInput={(value) => onChangeInput('hex', value)}
                                hexValue={hexValue}
                            />

                            <div className="color-picker-modal__picker__input-fields__rgb">
                                <NumberInputField
                                    className="color-picker-modal__picker__input-fields__rgb-input"
                                    value={rgbValue.r}
                                    min={0}
                                    max={255}
                                    onChange={(value) => onChangeInput('rgb r', value)}
                                />
                                <NumberInputField
                                    className="color-picker-modal__picker__input-fields__rgb-input"
                                    value={rgbValue.g}
                                    min={0}
                                    max={255}
                                    onChange={(value) => onChangeInput('rgb g', value)}
                                />
                                <NumberInputField
                                    className="color-picker-modal__picker__input-fields__rgb-input"
                                    value={rgbValue.b}
                                    min={0}
                                    max={255}
                                    onChange={(value) => onChangeInput('rgb b', value)}
                                />
                                {useAlpha && (
                                    <NumberInputField
                                        className="color-picker-modal__picker__input-fields__rgb-input"
                                        value={(rgbValue.a ?? 1) * 100}
                                        min={0}
                                        max={100}
                                        onChange={(value) => {
                                            const newValue = value / 100;
                                            onChangeInput('rgb a', newValue);
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    {generalColors && generalColors.length > 0 && (
                        <div className="color-picker-modal__predefined-colors">
                            <p>{Translation.get('colorPicker.generalColors', 'ui-components')}</p>
                            <div className="color-picker-modal__predefined-colors__color-list">
                                {generalColors.map((clr) => {
                                    const key = (() => {
                                        if (ColorHelpers.isSimpleColor(clr.color)) {
                                            return clr.color.hex;
                                        }

                                        return clr.color.points[0].color.hex;
                                    })();

                                    return (
                                        <Tooltip key={key} title={clr.title} placement="top">
                                            <button
                                                className="color-picker-modal__predefined-colors__btn"
                                                style={{ background: ColorHelpers.getCssColor(clr.color) }}
                                                onClick={() => handlePresetColors(clr)}
                                            />
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {brandColors && brandColors.length > 0 && (
                        <div className="color-picker-modal__predefined-colors">
                            <p>
                                {Translation.get('colorPicker.brandColors', 'ui-components', {
                                    brand: Setup.get('brands')[brandColors[0].brand] || brandColors[0].brand
                                })}
                            </p>
                            <div className="color-picker-modal__predefined-colors__color-list">
                                {brandColors.map((clr) => {
                                    const key = (() => {
                                        if (ColorHelpers.isSimpleColor(clr.color)) {
                                            return clr.color.hex;
                                        }

                                        return clr.color.points[0].color.hex;
                                    })();

                                    return (
                                        <Tooltip key={key} title={clr.title} placement="top">
                                            <button
                                                className="color-picker-modal__predefined-colors__btn"
                                                style={{ background: ColorHelpers.getCssColor(clr.color) }}
                                                onClick={() => handlePresetColors(clr)}
                                            />
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>
                    )}

                    {templateColors && templateColors.length > 0 && (
                        <div className="color-picker-modal__predefined-colors">
                            <p>{templateColors[0].brandLabel}</p>
                            <div className="color-picker-modal__predefined-colors__color-list">
                                {templateColors.map((clr) => {
                                    const key = (() => {
                                        if (ColorHelpers.isSimpleColor(clr.color)) {
                                            return clr.color.hex;
                                        }

                                        return clr.color.points[0].color.hex;
                                    })();

                                    return (
                                        <Tooltip key={key} title={clr.title} placement="top">
                                            <button
                                                className="color-picker-modal__predefined-colors__btn"
                                                style={{ background: ColorHelpers.getCssColor(clr.color) }}
                                                onClick={() => handlePresetColors(clr)}
                                            />
                                        </Tooltip>
                                    );
                                })}
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default CustomPicker(ColorPickerModal);
