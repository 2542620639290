import React from 'react';
import MuiListItemButton, { ListItemButtonProps as MuiListItemButtonProps } from '@mui/material/ListItemButton';

type ListItemButtonProps = Omit<MuiListItemButtonProps, 'classes' | 'sx'>;

/**
 * Renders a MUI ListItemButton component.
 *
 * - [MUI ListItemButton documentation](https://mui.com/material-ui/api/list-item/) - The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const ListItemButton = React.forwardRef<HTMLDivElement, ListItemButtonProps>(({ children, ...props }: ListItemButtonProps, ref) => {
    return (
        <MuiListItemButton ref={ref} {...props}>
            {children}
        </MuiListItemButton>
    );
});

ListItemButton.displayName = 'ListItemButton';

export default ListItemButton;
