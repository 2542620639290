import React, { useMemo } from 'react';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import classNames from 'classnames';
import { BrandGuideColor, Color, ColorOptions, SimpleColor } from 'types/color.type';
import { NumberInputField } from 'components/template-designer/components/ui-components/number-input-field';
import Translation from 'components/data/Translation';
import { ColorHelpers } from 'helpers/colors.helpers';
import Tooltip from 'components/ui-components-v2/Tooltip';
import useColorPicker from './hooks/useColorPicker';
import ColorPickerModal from './components/color-picker-modal';
import { HexInput } from './components/hex-input';
import './styles/main.scss';

interface Props {
    /**
     * The color to display in the color picker.
     * If the color is a SimpleColor, the color picker will display the color as is.
     * If the color is a Color object, the color picker will display the color based on the type of color.
     */
    color: Color | SimpleColor;
    /**
     * Function to call when the color changes.
     * @param color - The new color.
     * @returns void
     */
    onChange: (color: Color | SimpleColor) => void;
    /**
     * Whether to use gradients in the color picker.
     * Default is true.
     */
    useGradients?: boolean;
    /**
     * Whenever to use the pipette in the color picker.
     * Default is false.
     */
    usePipette?: boolean;
    /**
     * If the color picker should use the alpha channel.
     */
    useAlpha?: boolean;
    /**
     * Whenever the color picker is disabled.
     * Default is false.
     */
    disabled?: boolean;
    /**
     * The preset colors to display in the color picker.
     */
    presetColors?: BrandGuideColor[];
    /**
     * The size of the color picker.
     * Default is 'small'.
     */
    size?: 'small' | 'medium';
    /**
     * The prefix for the data-cy attribute.
     */
    dataCyPrefix?: string;
}

/**
 * The ColorPickr component renders an input field to change the color. When clicking on the color, a color picker modal will open to change the color in more detail.
 *
 * - [ColorPickr documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/668008449/ColorPicker)
 */
const ColorPicker = ({
    color,
    onChange,
    useGradients = true,
    usePipette = false,
    useAlpha = true,
    presetColors,
    disabled = false,
    size = 'small',
    dataCyPrefix = ''
}: Props) => {
    const {
        activeColor,
        hexValue,
        rgbValue,
        showColorPickerModal,
        anchorEl,
        handleShowColorPickerModal,
        onChangeColorType,
        onChangeRotation,
        onChangePoints,
        onChangeColor,
        onChangePreset,
        onChangeInput,
        activePoint,
        onChangeActivePoint
    } = useColorPicker(color, onChange, useGradients);

    /**
     * Gets the background color for the button.
     */
    const buttonBackground = useMemo(() => {
        if (!('type' in color)) return { background: color.hex };

        if (color.type === ColorOptions.Transparent) {
            return {
                background: ColorOptions.Transparent
            };
        }

        if (color.type === ColorOptions.Solid) {
            if (activeColor.rgb && activeColor.rgb.a !== undefined) {
                const { r, g, b, a } = activeColor.rgb;
                return {
                    background: `linear-gradient(90deg, rgba(${r}, ${g}, ${b}, 1) 0%, rgba(${r}, ${g}, ${b}, 1) 49.9%, rgba(${r}, ${g}, ${b}, ${a}) 50.1%, rgba(${r}, ${g}, ${b}, ${a}) 100%)`
                };
            }

            return {
                background: activeColor.hex
            };
        }

        if ([ColorOptions.Linear, ColorOptions.Radial].includes(color.type)) {
            return {
                background: ColorHelpers.getCssColor(color)
            };
        }

        return {
            background: activeColor.hex
        };
    }, [color, activeColor]);

    const colorType = ColorHelpers.getColorType(color);
    const colorRotation = ColorHelpers.getColorRotation(color);
    const colorPoints = ColorHelpers.getColorPoints(color);

    return (
        <div className="color-picker">
            <HexInput
                buttonBackground={buttonBackground}
                dataCy={dataCyPrefix + '-text-input'}
                disabled={disabled}
                handleShowColorPickerModal={handleShowColorPickerModal}
                hexValue={hexValue}
                onChangeInput={(value) => onChangeInput('hex', value)}
                showColorButton
                size={size}
                colorType={colorType}
            />

            {useAlpha && colorType !== ColorOptions.Transparent && (
                <Tooltip title={Translation.get('labels.opacity', 'common')} disableInteractive>
                    <div>
                        <NumberInputField
                            className={classNames('color-picker__opacity-input', `color-picker__opacity-input--${size}`)}
                            size={size}
                            onChange={(value) => {
                                const newValue = value / 100;
                                onChangeInput('rgb a', newValue);
                            }}
                            min={0}
                            max={100}
                            showNumericInputs={false}
                            value={(rgbValue.a ?? 1) * 100}
                            disabled={disabled}
                        />
                    </div>
                </Tooltip>
            )}

            <ClickAwayListener onClickAway={handleShowColorPickerModal} mouseEvent="onMouseDown">
                <Popper open={showColorPickerModal} anchorEl={anchorEl as HTMLElement} placement="bottom" className="color-picker__popover">
                    {showColorPickerModal && (
                        <ColorPickerModal
                            color={activeColor}
                            hexValue={hexValue}
                            rgbValue={rgbValue}
                            onChangeColorType={onChangeColorType}
                            onChangeRotation={onChangeRotation}
                            onChangePoints={onChangePoints}
                            onChange={onChangeColor}
                            onChangePreset={onChangePreset}
                            onChangeInput={onChangeInput}
                            activePoint={activePoint}
                            onChangeActivePoint={onChangeActivePoint}
                            colorType={colorType}
                            colorPoints={colorPoints}
                            presetColors={presetColors}
                            rotation={colorRotation}
                            useGradients={useGradients}
                            usePipette={usePipette}
                            useAlpha={useAlpha}
                        />
                    )}
                </Popper>
            </ClickAwayListener>
        </div>
    );
};

export { ColorPicker };
