import React from 'react';
import classNames from 'classnames';
import GenericIcon from 'components/ui-components/GenericIcon';
import IllustrationsBackground from './components/illustration-background';
import './styles/main.scss';

export type IllustrationType =
    | 'fallback'
    | 'chat'
    | 'media'
    | 'images'
    | 'template-designer'
    | 'publish'
    | 'no-result'
    | 'campaigns'
    | 'add'
    | 'feed'
    | 'upload'
    | 'pdf'
    | 'audio'
    | 'video'
    | 'display-ad'
    | 'image'
    | 'after-effects'
    | 'inDesign'
    | 'mail'
    | 'zip'
    | 'collection'
    | 'template'
    | 'from-scratch'
    | 'layer-inputs';

export interface IllustrationProps {
    className?: string;
    illustration: IllustrationType;
    size?: 'small' | 'medium' | 'large' | 'inherit';
    color?: 'disabled' | 'primary' | 'secondary';
    noSquarcle?: boolean;
}

const Illustration = ({ className, illustration = 'fallback', size = 'medium', color = 'primary', noSquarcle = false }: IllustrationProps) => {
    return (
        <div className={classNames('ui-v2-cape-illustration', { [`${className}`]: className, 'ui-v2-cape-illustration--no-squarcle': noSquarcle })}>
            <GenericIcon
                svgClassName="ui-v2-cape-illustration__svg"
                className={`ui-v2-cape-illustration__icon ui-v2-cape-illustration__icon--size-${size} ui-v2-cape-illustration__icon--color-${color}`}
                icon={`illustrations-${illustration}`}
            />
            {!noSquarcle && <IllustrationsBackground color={color} size={size} />}
        </div>
    );
};

export default Illustration;
