import React from 'react';
import MuiToggleButtonGroup, { ToggleButtonGroupProps as MuiToggleButtonGroupProps } from '@mui/material/ToggleButtonGroup';
import classNames from 'classnames';

export interface ToggleButtonGroupProps extends Omit<MuiToggleButtonGroupProps, 'classes' | 'xs'> {
    variant?: 'contained' | 'standard';
}

/**
 * Renders a MUI ToggleButtonGroup component.
 *
 * - [ToggleButtonGroup documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/336036189/ToggleButton)
 * - [API documentation](https://mui.com/material-ui/api/toggle-button-group/)- The 'xs' and 'classes' props are omitted,
 * please don't use them.
 */

const ToggleButtonGroup = React.forwardRef<HTMLDivElement, ToggleButtonGroupProps>(({ children, variant, ...props }: ToggleButtonGroupProps, ref) => {
    props.color = props.color ? props.color : 'standard';
    return (
        <MuiToggleButtonGroup
            classes={{ root: classNames('ui-v2-toggle-button-group__' + props.color, { ['ui-v2-toggle-button-group__' + variant]: variant === 'contained' }) }}
            ref={ref}
            {...props}>
            {children}
        </MuiToggleButtonGroup>
    );
});

ToggleButtonGroup.displayName = 'ToggleButtonGroup';

export default ToggleButtonGroup;
