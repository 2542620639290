import React, { useState } from 'react';
import classNames from 'classnames';
import { InputLabelProps } from '@mui/material';
import Typography from 'components/ui-components-v2/Typography';
import Icon from 'components/ui-components-v2/Icon';
import Tooltip from 'components/ui-components-v2/Tooltip';
import MenuWithHelperText, { MenuWithHelperTextItem } from '../MenuWithHelperText';
import './styles/main.scss';

interface Props {
    /**
     * Additional class name for the input wrapper.
     */
    className?: string;
    /**
     * Label for the input.
     */
    label?: string;
    /**
     * Custom label for the input.
     * If provided, the label prop will be ignored.
     * The custom label should be a React component where the following props are passed:
     * - label: React element for the label.
     * - setCanInputOptionsBeOpened: Function to set if the input options menu can be opened.
     * - setChangingValue: Function to set if the value is being changed.
     */
    customLabel?: React.ReactNode;
    /**
     * Color of the label.
     * The background color of the label will also be changed.
     * @default 'primary'
     */
    labelColor?: InputLabelProps['color'];
    /**
     * Options for the label dropdown.
     * These options will be displayed when the label is clicked.
     */
    labelDropdownOptions?: MenuWithHelperTextItem[];
    /**
     * Tooltip for the input.
     * A tooltip icon will be displayed next to the label.
     * This will be displayed next to the label.
     */
    tooltip?: string;
    /**
     * Helper text for the input.
     * This will be displayed below the input.
     */
    helperText?: string;
    /**
     * Error message for the input.
     * This will be displayed below the input.
     * If provided, an error prop of true is passed to the child input component.
     */
    errorMessage?: string;
    /**
     * If true, a warning icon will be displayed next to the label.
     * It will replace the tooltip icon if provided.
     */
    hasWarning?: boolean;
    /**
     * Tooltip for the warning icon.
     * It can be a string or a React component.
     * This will be displayed when hovering the warning icon.
     * When hasWarning is false, this prop will be ignored.
     */
    warningTooltip?: string | React.ReactNode;
    /**
     * Size of the input wrapper.
     * - 'small': Label will be on the left of the input.
     * - 'medium': Label will be on top of the input.
     * @default 'small'
     */
    size?: 'small' | 'medium';
    /**
     * Children of the input wrapper.
     * This should be the input component.
     */
    children: React.ReactNode;
    /**
     * If true, the input will have a bottom margin.
     */
    gutterBottom?: boolean;
    /**
     * If true, the input will have a top margin.
     */
    gutterTop?: boolean;
}

/**
 * The InputWrapper component is a wrapper around the input component. It displays a label, input, helper text, and error message.
 *
 * - [InputWrapper documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/620822529/InputWrapper)
 */
const InputWrapper = ({
    className,
    label,
    labelColor,
    labelDropdownOptions,
    tooltip,
    helperText,
    errorMessage,
    hasWarning,
    warningTooltip,
    size = 'medium',
    customLabel,
    children,
    gutterBottom = false,
    gutterTop = false
}: Props) => {
    /**
     * To keep track if the input options menu can be opened.
     * If the mouse is down for more than 200ms, the input options menu should not be opened.
     */
    const [canInputOptionsBeOpened, setCanInputOptionsBeOpened] = useState(true);

    /**
     * To keep track if the value is being changed.
     */
    const [changingValue, setChangingValue] = useState(false);

    /**
     * Label element with tooltip.
     */
    const labelElement = (
        <MenuWithHelperText
            canBeOpened={canInputOptionsBeOpened}
            trigger={
                <div className="ui-v2-cape-input-wrapper__label-container">
                    <Typography
                        className={classNames('ui-v2-cape-input-wrapper__label', {
                            [`ui-v2-cape-input-wrapper__label--${labelColor}`]: !!labelColor,
                            [`ui-v2-cape-input-wrapper__label--changing-value`]: changingValue,
                            [`ui-v2-cape-input-wrapper__label--has-dropdown-options`]: labelDropdownOptions?.length && !changingValue
                        })}
                        variant={size === 'medium' ? 'body2' : 'caption'}
                        color={labelColor}>
                        {label}
                    </Typography>

                    {tooltip && !hasWarning && (
                        <Tooltip title={tooltip}>
                            <Icon className="ui-v2-cape-input-wrapper__tooltip">info</Icon>
                        </Tooltip>
                    )}

                    {hasWarning && (
                        <Tooltip title={warningTooltip}>
                            <Icon className="ui-v2-cape-input-wrapper__warning">warning</Icon>
                        </Tooltip>
                    )}
                </div>
            }
            items={labelDropdownOptions ?? []}
        />
    );

    return (
        <div
            className={classNames('ui-v2-cape-input-wrapper', `ui-v2-cape-input-wrapper--${size}`, className, {
                'ui-v2-cape-input-wrapper--gutterBottom': gutterBottom,
                'ui-v2-cape-input-wrapper--gutterTop': gutterTop
            })}>
            <div className="ui-v2-cape-input-wrapper__input-holder__label-container">
                {customLabel ? (
                    <>
                        {React.cloneElement(
                            customLabel as React.ReactElement<
                                unknown & {
                                    label: React.ReactElement;
                                    setCanInputOptionsBeOpened: (open: boolean) => void;
                                    setChangingValue: (changing: boolean) => void;
                                }
                            >,
                            {
                                label: labelElement,
                                setCanInputOptionsBeOpened,
                                setChangingValue
                            }
                        )}
                    </>
                ) : (
                    <>{labelElement}</>
                )}
            </div>
            <div className="ui-v2-cape-input-wrapper__input-holder__input-container">
                {children &&
                    React.isValidElement(children) &&
                    React.cloneElement(children as React.ReactElement, {
                        error: errorMessage,
                        size
                    })}
                {errorMessage && (
                    <Typography className="ui-v2-cape-input-wrapper__input-holder__input-error-text" variant="caption" color="red">
                        {errorMessage}
                    </Typography>
                )}
                {helperText && (
                    <Typography className="ui-v2-cape-input-wrapper__input-holder__input-helper-text" variant="caption">
                        {helperText}
                    </Typography>
                )}
            </div>
        </div>
    );
};

export default InputWrapper;
