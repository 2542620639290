import React, { useRef } from 'react';
import classNames from 'classnames';
import { ColorOptions } from 'types/color.type';
import TextField from 'components/ui-components-v2/TextField';
import Translation from 'components/data/Translation';
import { ColorHelpers } from 'helpers/colors.helpers';
import '../styles/hex-input.scss';

interface Props {
    className?: string;
    showColorButton: boolean;
    size?: 'small' | 'medium';
    onChangeInput: (value: string) => void;
    colorType: ColorOptions;
    hexValue: string;
    disabled?: boolean;
    handleShowColorPickerModal?: () => void;
    buttonBackground?: React.CSSProperties;
    dataCy?: string;
}

const HexInput = ({
    className,
    showColorButton,
    dataCy,
    size,
    onChangeInput,
    colorType,
    hexValue,
    disabled,
    handleShowColorPickerModal,
    buttonBackground
}: Props) => {
    const inputRef = useRef<HTMLInputElement>(null);

    return (
        <TextField
            className={classNames('color-picker-hex-input', className)}
            data-cy={dataCy}
            size={size}
            fullWidth
            onChange={(event) => {
                const value = event.target.value;
                onChangeInput(value);
            }}
            onBlur={(event) => {
                let value = event.target.value;

                if (value.length < ColorHelpers.HEX_LENGTH) {
                    value = value.padEnd(ColorHelpers.HEX_LENGTH, value.charAt(value.length - 1));
                }

                if (value.length > ColorHelpers.HEX_LENGTH) {
                    value = value.slice(0, ColorHelpers.HEX_LENGTH);
                }

                value = value.charAt(0) !== '#' ? '#' + value : value;
                value = value.padEnd(ColorHelpers.HEX_LENGTH, value.charAt(value.length - 1));
                onChangeInput(value);
            }}
            value={(() => {
                if (colorType === ColorOptions.Transparent) return Translation.get('general.colorOptions.transparent', 'template-designer');

                const value = hexValue.toUpperCase();

                if (value.length > ColorHelpers.HEX_LENGTH) {
                    return value.slice(0, ColorHelpers.HEX_LENGTH);
                }

                return value;
            })()}
            inputRef={inputRef}
            disabled={disabled}
            name="hex"
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    inputRef.current && inputRef.current.blur();
                }
            }}
            InputProps={{
                startAdornment: showColorButton && (
                    <div className={classNames('color-picker__color-container', `color-picker__color-container--${size}`)}>
                        <button
                            style={buttonBackground}
                            onClick={handleShowColorPickerModal}
                            className={classNames('color-picker__button', disabled && 'color-picker__button--disabled')}
                        />
                        <div className="color-picker__grid" />
                    </div>
                )
            }}
        />
    );
};

export { HexInput };
