import React, { useEffect, useMemo } from 'react';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { isEqual } from 'lodash';
import Avatar, { AvatarProps } from 'components/ui-components-v2/Avatar';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Icon from 'components/ui-components-v2/Icon';
import Translation from 'components/data/Translation';
import Button from 'components/ui-components-v2/Button';
import { Item } from './interfaces/Item';
import SearchUi from './components/SearchUi';
import MultiAvatars from './components/MultiAvatars';
import './styles/main.scss';

interface UserSelectorProps {
    className?: string;
    users: Item[];
    teams?: Item[];
    value: Item | Item[] | undefined;
    avatarSize?: Exclude<AvatarProps['size'], 'small'>;
    multiple?: boolean;
    onMutation: (items: Item[]) => void;
}

/**
 * The User Selector displays a clickable Avatar. When clicked, a popover with Autocomplete renders showing the search ui with selectable users.
 *
 * - [UserSelector documentation](https://bycape.atlassian.net/wiki/spaces/DD/pages/498597889/UserSelector)
 *
 * @param className - Add a className to the Button wrapped around the Avatar.
 * @param users - Array of user Items that are available to select.
 * @param teams - Array of team Items that are available to select.
 * @param value - The value of the autocomplete, must be an array when 'multiple'.
 * @param multiple - If true, value must be an array and multiple options can be selected.
 * @param avatarSize - Change the clickable avatar size
 * @param onMutation - Callback for when an item is selected by the user in the autocomplete component.
 *
 */
const UserSelector: React.FC<UserSelectorProps> = ({ className, value, users, teams, avatarSize = 'medium', multiple, onMutation }) => {
    const [newValue, setNewValue] = React.useState<Item | Item[] | undefined>(value);
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
    const [changeOrCloseReason, setChangeOrCloseReason] = React.useState<string>('');

    useEffect(() => {
        setNewValue(value);
    }, [value]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    //Handles onChange from the autocomplete
    const onChange = (event, value, reason) => {
        setChangeOrCloseReason(reason);
        if (reason === 'onClearIcon' && !multiple) {
            setNewValue([]);
            handleClose();
            return;
        }
        if (reason === 'onClearIcon' && multiple) {
            setNewValue([]);
            return;
        }
        if (reason === 'backdropClick') {
            handleClose();
            return;
        }
        setNewValue(value);
        if (!multiple) handleClose();
    };

    //Writes away data when the animation is finished
    const onExited = () => {
        //The user clicks backdrop when single select, do nothing
        if (changeOrCloseReason === 'backdropClick' && !multiple) {
            return;
        }
        //Values are equal, do nothing
        if (isEqual(value, newValue)) {
            return;
        }

        //Value is undefined (e.g. clearIcon), empty selected member
        if (newValue === undefined) {
            onMutation([]);
            return;
        }

        if (Array.isArray(newValue)) {
            //Set multi select
            onMutation(newValue);
        } else {
            //Set single select
            onMutation([newValue]);
        }
    };

    //Prep: adds itemType and merges users and teams array (if teams is provided) for autocomplete options
    const prepDataForUI = (users: Item[], teams: Item[] | undefined): Item[] => {
        users.forEach((user, i) => {
            users[i].itemType = 'user';
        });
        let mergedArray: Item[] = [];
        if (teams) {
            teams.forEach((team, i) => {
                teams[i].itemType = 'team';
            });
            mergedArray = teams.concat(users);
        }
        return mergedArray.length === 0 ? users : mergedArray;
    };

    const open = Boolean(anchorEl);
    const MemoedSearchUi = useMemo(
        () => <SearchUi multiple={multiple} defaultValue={value} onChange={onChange} options={prepDataForUI(users, teams)} />,
        [value, users, teams]
    );

    return (
        <>
            {multiple ? (
                <Button size="small" onClick={handleClick} endIcon={<Icon>loop</Icon>}>
                    {newValue && <MultiAvatars value={newValue as Item[]} avatarSize={avatarSize} />}
                </Button>
            ) : (
                <Tooltip title={(value as Item)?.name ? (value as Item)?.name : Translation.get('actions.unassigned', 'common')}>
                    <IconButton className={'ui-v2-user-selector' + (className ? ' ' + className : '')} size="small" onClick={handleClick}>
                        <Avatar size={avatarSize} src={(value as Item)?.profilePicture}>
                            {(value as Item)?.name ? (value as Item)?.name : <Icon fontSize="small">person_add</Icon>}
                        </Avatar>
                    </IconButton>
                </Tooltip>
            )}
            <Popover
                TransitionProps={{
                    onExited: onExited
                }}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                slotProps={{ paper: { className: 'ui-v2-user-selector__popover' } }}
                open={open}
                anchorEl={anchorEl}
                onClose={(event, reason) => {
                    onChange(event, newValue, reason);
                }}>
                {MemoedSearchUi}
            </Popover>
        </>
    );
};

export default UserSelector;
